<template>
  <div>
    <validation-observer
      ref='form'
      v-slot='{invalid}'
    >
      <b-card v-if='!isDataLoaded'>
        <div class='text-center text-success my-2'>
          <b-spinner class='align-middle' />
        </div>
      </b-card>
      <b-form
        v-else
        novalidate
        @submit.prevent='onSubmit'
      >
        <b-row>
          <b-col
            cols='12'
            xl='9'
            md='8'
          >
            <b-card>
              <b-row>
                <b-col cols='6'>
                  <LocaleTabs
                    :errors='formErrors'
                    name='title'
                  >
                    <template
                      v-for='(i,k,idx) in languages'
                      :slot='`lang${idx}`'
                      slot-scope='slotScope'
                    >
                      <ValidationProvider
                        :key='idx'
                        v-slot='{ errors }'
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid='`title.${k}`'
                        :name="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        rules='required'
                      >
                        <b-form-group
                          :label="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        >
                          <b-form-input
                            v-model='form.title[k]'
                            :placeholder="$t('general.field_lang', { field: $t('labels.title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                          />
                          <small class='text-danger'>{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>
                <b-col cols='6'>
                  <LocaleTabs
                    :errors='formErrors'
                    name='seo_title'
                  >
                    <template
                      v-for='(i,k,idx) in languages'
                      :slot='`lang${idx}`'
                      slot-scope='slotScope'
                    >
                      <ValidationProvider
                        :key='idx'
                        v-slot='{ errors }'
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid='`seo_title.${k}`'
                        :name="$t('general.field_lang', { field: $t('labels.seo_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        rules='required'
                      >
                        <b-form-group
                          :label="$t('general.field_lang', { field: $t('labels.seo_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        >
                          <b-form-input
                            v-model='form.seo_title[k]'
                            :placeholder="$t('general.field_lang', { field: $t('labels.seo_title'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                          />
                          <small class='text-danger'>{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>
                <b-col cols='12'>
                  <validation-provider
                    v-slot='{ errors }'
                    vid='tags'
                    :name="$t('labels.keywords')"
                  >
                    <b-form-group :label="$t('labels.keywords')">
                      <b-form-tags
                        v-model='form.keywords'
                        input-id='tags-pills'
                        tag-variant='primary'
                        tag-pills
                        size='md'
                        separator=' '
                        :placeholder="$t('labels.keywords')"
                        :add-button-text="$t('labels.add')"
                      />


                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols='12'>
                  <LocaleTabs
                    :errors='formErrors'
                    name='description'
                  >
                    <template
                      v-for='(i,k,idx) in languages'
                      :slot='`lang${idx}`'
                      slot-scope='slotScope'
                    >
                      <ValidationProvider
                        :key='idx'
                        v-slot='{ errors }'
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid='`description.${k}`'
                        :name="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        rules='required'
                      >
                        <b-form-group
                          :label="$t('general.field_lang', { field: $t('labels.description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        >
                          <quill-editor
                            v-model='form.description[k]'
                            :options='snowOption'
                          />
                          <small class='text-danger'>{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>
                <b-col cols='6'>
                  <LocaleTabs
                    :errors='formErrors'
                    name='seo_description'
                  >
                    <template
                      v-for='(i,k,idx) in languages'
                      :slot='`lang${idx}`'
                      slot-scope='slotScope'
                    >
                      <ValidationProvider
                        :key='idx'
                        v-slot='{ errors }'
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid='`seo_description.${k}`'
                        :name="$t('general.field_lang', { field: $t('labels.seo_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      >
                        <b-form-group
                          :label="$t('general.field_lang', { field: $t('labels.seo_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        >
                          <b-form-textarea
                            v-model='form.seo_description[k]'
                            :placeholder="$t('general.field_lang', { field: $t('labels.seo_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                          />
                          <small class='text-danger'>{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>
                <b-col cols='6'>
                  <LocaleTabs
                    :errors='formErrors'
                    name='short_description'
                  >
                    <template
                      v-for='(i,k,idx) in languages'
                      :slot='`lang${idx}`'
                      slot-scope='slotScope'
                    >
                      <ValidationProvider
                        :key='idx'
                        v-slot='{ errors }'
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid='`short_description.${k}`'
                        :name="$t('general.field_lang', { field: $t('labels.short_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      >
                        <b-form-group
                          :label="$t('general.field_lang', { field: $t('labels.short_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        >
                          <b-form-textarea
                            v-model='form.short_description[k]'
                            :placeholder="$t('general.field_lang', { field: $t('labels.short_description'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                          />
                          <small class='text-danger'>{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col
            cols='12'
            xl='3'
            md='4'
          >
            <b-card>
              <b-row>
                <b-col cols='12'>
                  <ValidationProvider
                    v-slot='{ errors }'
                    vid='image'
                    :name="$t('labels.image')"
                    rules='required'
                  >
                    <ImagePreview
                      v-model='image'
                      :label="$t('labels.image')"
                    />
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-col>
                <b-col cols='12' />
              </b-row>
              <!--              <hr>-->
              <!--              <b-col-->
              <!--                cols='12'-->
              <!--              >-->
              <!--                <validation-provider-->
              <!--                  v-slot='{ errors }'-->
              <!--                  vid='blog_category_id'-->
              <!--                  :name="$t('Blog Categories')"-->
              <!--                 >-->
              <!--                  <b-form-group-->
              <!--                    :label="$t('Blog Categories')"-->
              <!--                  >-->
              <!--                    <v-select-->
              <!--                      v-model='form.blog_category_id'-->
              <!--                      :dir='$store.state.appConfig.layout.direction'-->
              <!--                      label='title'-->
              <!--                      :options='blog_categories'-->
              <!--                      :reduce='item => item.id'-->
              <!--                      :placeholder="$t('Blog Categories')"-->
              <!--                    />-->
              <!--                    <small class='text-danger'>{{ errors[0] }}</small>-->
              <!--                  </b-form-group>-->
              <!--                </validation-provider>-->
              <!--              </b-col>-->
              <hr>
              <b-col
                cols='12'
              >
                <validation-provider
                  v-slot='{ errors }'
                  vid='status'
                  :name="$t('status')"
                  rules='required'
                >
                  <b-form-group
                    :label="$t('status')"
                  >
                    <v-select
                      v-model='form.status'
                      :dir='$store.state.appConfig.layout.direction'
                      label='name'
                      :options='statuses'
                      :reduce='item => item.id'
                      :placeholder="$t('status')"
                    />
                    <small class='text-danger'>{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <hr>
              <b-row>
                <b-col cols='12'>
                  <div class='d-flex align-items-center justify-content-between'>
                    <b-card-text class='mb-0'>
                      {{ $t('labels.show_homepage') }}
                    </b-card-text>
                    <b-form-checkbox
                      v-model='form.show_homepage'
                      checked='true'
                      value='true'
                      unchecked-value='false'
                      name='check-button'
                      switch
                    />
                  </div>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols='12'>
                  <div class='d-flex align-items-center justify-content-between'>
                    <b-card-text class='mb-0'>
                       {{ $t('labels.is_main') }}
                    </b-card-text>
                    <b-form-checkbox
                      v-model='form.is_main'
                      checked='true'
                      value='true'
                      unchecked-value='false'
                      name='check-button'
                      switch
                    />
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols='12'>
                  <div class='d-flex align-items-center justify-content-between mt-1'>
                    <b-card-text class='mb-0'>
                      {{ $t('labels.is_notification') }}
                    </b-card-text>
                    <b-form-checkbox
                      v-model='form.is_notification'
                      checked='true'
                      value='true'
                      unchecked-value='false'
                      name='check-button'
                      switch
                    />
                  </div>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols='12 text-center'>
                  <LoadingButton />
                  <b-button
                    type='reset'
                    variant='outline-secondary'
                  >
                    {{ $t('labels.reset') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import _ from 'lodash'
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      blog_categories: [],
      image: null,
      snowOption: {
        theme: 'snow',
      },
      form: {
        title: {
          en: null,
          ar: null,
        },
        seo_title: {
          en: null,
          ar: null,
        },
        description: {
          en: null,
          ar: null,
        },
        seo_description: {
          en: null,
          ar: null,
        },
        short_description: {
          en: null,
          ar: null,
        },
        // blog_category_id: null,
        show_homepage: true,
        keywords: [],
        image: null,
        status: null,
        is_main: false,
        is_notification:false,
      },
      isDataLoaded: false,
    }
  },

  computed: {
    statuses() {
      return [
        {
          id: 0,
          name: this.$t('statuses.0'),
        },
        {
          id: 1,
          name: this.$t('statuses.1'),
        },
        {
          id: 2,
          name: this.$t('statuses.2'),
        },
      ]
    },
  },
  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
  },
  created() {
    this.getCategories()
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    getCategories() {
      this.axios.get('/blog-categories/list')
        .then(res => {
          const { data } = res.data
          this.blog_categories = data
        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            title: data.title,
            seo_title: data?.seo_title['ar'] ?? false ? data.seo_title : { en: null, ar: null },
            description: data?.description['ar'] ?? false ? data.description : { en: null, ar: null },
            seo_description: data?.seo_description['ar'] ?? false ? data.seo_description : { en: null, ar: null },
            short_description: data?.short_description['ar'] ?? false ? data.short_description : { en: null, ar: null },
            // blog_category_id: data.blog_category_id,
            show_homepage: data.show_homepage,
            keywords: data.keywords,
            status: data.status,
            is_main: data.is_main,
            is_notification:data.is_notification
          }
          this.image = data.image_path
          this.isDataLoaded = true
        })
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (this.isEdit) {
        if (!($form.image instanceof File)) {
          delete $form.image
        }
        this.form_data = {
          ...$form,
          _method: 'put',
        }
      }
      return $form
    },
  },
}
</script>

